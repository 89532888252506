<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">Forms</h2>

    <!--begin:: Fieldset-->
    <b-form-group>
      <label class="checkbox">
        <input type="checkbox" v-model="v.supplementalForm.use_supplemental_forms.$model" />
        <span class="mr-2"></span> {{ labels.use_supplemental_forms }}
      </label>
      <b-collapse
        id="accordion-1"
        v-model="v.supplementalForm.use_supplemental_forms.$model"
        accordion="my-accordion"
        role="tabpanel"
      >
        <div class="position-relative pt-6 pb-1 px-6">
          <table v-if="supplementals.length > 0" class="table table-notopborder">
            <tbody>
              <template v-for="(doc, name) in supplementals">
                <tr :key="name" :class="{ 'pointer-events-none': doc.loading }">
                  <td class="align-middle">
                    <div class="font-weight-600 font-size-14">
                      {{ doc.name }}
                    </div>
                  </td>
                  <td class="text-right">
                    <b-dropdown class="btn-icon" variant="clean" menu-class="w-max-content" right no-caret>
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor font-size-md pr-0"></i>
                      </template>

                      <ul class="navi navi-hover py-5">
                        <li class="navi-item">
                          <a
                            class="navi-link"
                            v-if="doc.file && typeof doc.file == 'string'"
                            :href="doc.file"
                            target="_blank"
                          >
                            <span class="navi-icon">
                              <i class="la la-download font-size-h1 mr-2"></i>
                            </span>
                            <span class="navi-text">Download</span>
                          </a>
                        </li>

                        <li class="navi-item">
                          <a class="navi-link" href="javascript:;" @click="openModal(doc)">
                            <span class="navi-icon">
                              <i class="la la-edit font-size-h1 mr-2"></i>
                            </span>
                            <span class="navi-text">Edit</span>
                          </a>
                        </li>

                        <li class="navi-item">
                          <a class="navi-link" href="javascript:;" @click="openModal(doc, true)">
                            <span class="navi-icon">
                              <i class="la la-trash font-size-h1 mr-2"></i>
                            </span>
                            <span class="navi-text">Delete</span>
                          </a>
                        </li>
                      </ul>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <b-btn variant="light-primary" class="font-weight-bolder" @click="openModal({})">
            <i class="fas fa-plus"></i>
            Add
          </b-btn>
          <spinner v-if="loading" bg-lighter />
        </div>
      </b-collapse>
    </b-form-group>
    <hr />
    <b-form-group>
      <label class="checkbox">
        <input type="checkbox" v-model="v.supplementalForm.background_check.$model" />
        <span class="mr-2"></span> {{ labels.background_check }}
      </label>
    </b-form-group>
    <!--end:: Fieldset-->
    <b-modal v-model="modal" z-index-10400 :title="`${isEdit ? 'Edit' : 'Add'}`" centered footer-class="text-right">
      <b-form-group>
        <template v-slot:label>
          Name
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="text"
          class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          required
          v-model="$v.form.name.$model"
          :disabled="Boolean(isEdit)"
          :state="validateState($v.form.name)"
        />
      </b-form-group>

      <b-form-group>
        <template v-slot:label>
          File
          <span class="text-danger">*</span>
        </template>
        <b-btn variant="light-primary" class="font-weight-bolder" @click="focusFileInput(`file_upload`)">
          <i class="fas fa-file"></i>
          Upload file
        </b-btn>
        <input
          type="file"
          style="display: none;"
          :ref="`file_upload`"
          accept="application/pdf"
          @change="handleFileUploaded"
        />
        <div v-if="form.file_url" class="mt-2 d-flex align-items-center">
          <span class="form-text  mr-5">
            Previously uploaded file
          </span>
          <a :href="form.file_url" class="mr-5" target="_blank">
            <i class="flaticon2-download"></i>
          </a>
        </div>
      </b-form-group>

      <template v-slot:modal-footer>
        <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="handleSubmit">
          {{ isEdit ? 'Save' : 'Add' }}
        </b-btn>
      </template>
    </b-modal>

    <b-modal v-model="confirmationModal" z-index-10400 centered title="Attention" title-class="h4" hide-footer>
      <b-card-text>Are you sure you want to <b class="text-danger">delete</b> </b-card-text>
      <div class="d-flex justify-content-end">
        <b-btn variant="primary" class="mr-2" v-cs-loading="submiting" @click="destroyItem">Delete</b-btn>
        <b-btn variant="white" @click="confirmationModal = false">Cancel</b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, integer, maxLength, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
    cooperative: {
      type: Object,
      default: () => {},
    },
  },
  validations: {
    form: {
      id: {},
      name: { required },
      file: { required },
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      modal: false,
      confirmationModal: false,
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      supplementals: 'supplemental/items',
    }),
    isEdit() {
      return this.form.id;
    },
  },
  created() {
    this.handleSubpplements();
    this.initForm();
  },
  methods: {
    ...mapActions({
      getSupplemental: 'supplemental/index',
      createSupplemental: 'supplemental/create',
      updateSupplemental: 'supplemental/update',
      deleteSupplemental: 'supplemental/delete',
    }),
    async handleSubpplements() {
      this.loading = true;
      await this.$await(
        this.getSupplemental({
          where: {
            cooperative_id: this.cooperative.id,
          },
        })
      );
      this.loading = false;
    },
    openModal(data, isDelete = false) {
      this.initForm(data);

      if (isDelete) {
        this.confirmationModal = true;
      } else {
        this.modal = true;
      }
    },
    initForm(data = {}) {
      this.form = {
        id: data.id || '',
        name: data.name || '',
        file: data.file || '',
        file_url: data.file || '',
        cooperative_id: this.cooperative.id,
      };
      this.$v.form.$reset();
    },
    focusFileInput(ref) {
      this.$refs[ref].click();
    },
    async handleFileUploaded(e) {
      const file = e.target.files.item(0);
      if (file) {
        this.form.file = file;
      }
    },
    handleSubmit() {
      if (!this.$v.form.$invalid) {
        const action = this.isEdit ? this.updateSupplemental : this.createSupplemental;
        const params = this.$_.cloneDeep(this.form);

        if (typeof params.file == 'string') delete params.file;
        delete params.file_url;

        this.submiting = true;

        action(this.jsonToFormData({ supplemental_form: params }))
          .then((result) => {
            this.modal = false;
            setTimeout(() => {
              this.handleSubpplements();
            }, 1000);
          })
          .finally((err) => {
            this.submiting = false;
          });
      } else {
        this.alertValidationFields(this.$v.form, { name: 'Name', file: 'File' });
        this.$v.form.$touch();
      }
    },
    destroyItem() {
      this.submiting = true;
      this.deleteSupplemental({ id: this.form.id })
        .then((result) => {
          this.confirmationModal = false;
        })
        .finally((err) => {
          this.submiting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
[z-index-10400] {
  z-index: 10400 !important;
}
</style>
