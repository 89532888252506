<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">{{ v.form.contact_type.$model }}</h2>
    <h5 class="text-muted text-center mb-12">
      Please provide us with {{ v.form.contact_type.$model.toLowerCase() }} information
    </h5>
    <template v-if="!miniMode">
      <!--begin:: Fieldset-->
      <template v-if="!hideInfoArea">
        <b-form-group>
          <template v-slot:label>
            {{ labels.company_name }}
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.form.company_name.$model"
            :state="validateState(v.form.company_name)"
          />
        </b-form-group>

        <b-row>
          <b-col lg="6">
            <b-form-group>
              <template v-slot:label>
                {{ labels.first_name }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.first_name.$model"
                :state="validateState(v.form.first_name)"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <template v-slot:label>
                {{ labels.last_name }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.last_name.$model"
                :state="validateState(v.form.last_name)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <!--end:: Fieldset-->

      <!--begin:: Fieldset-->
      <template v-if="!hideContactArea">
        <h3 class="text-dark text-center my-12">Contact Information</h3>
        <b-row>
          <b-col lg="6">
            <b-form-group>
              <template v-slot:label>
                {{ labels.email }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.email.$model"
                :state="validateState(v.form.email)"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <template v-slot:label>
                {{ labels.phone }}
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.phone.$model"
                v-mask="'(###)###-####'"
                :state="validateState(v.form.phone)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <!--end:: Fieldset-->

      <!--begin:: Fieldset-->
      <template v-if="!hideAddressArea">
        <h3 class="text-dark text-center my-12">Address</h3>
        <b-row>
          <b-col lg="8">
            <b-form-group>
              <template v-slot:label>
                {{ labels.street_address }}
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.street_address.$model"
                :state="validateState(v.form.street_address)"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <template v-slot:label>
                {{ labels.suite }}
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.suite.$model"
                :state="validateState(v.form.suite)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group>
              <template v-slot:label>
                {{ labels.city }}
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                @keypress="onlyLetter($event)"
                v-model="v.form.city.$model"
                :state="validateState(v.form.city)"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <template v-slot:label>
                {{ labels.state }}
              </template>
              <b-form-select
                :options="stateOptions"
                class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.state.$model"
                :state="validateState(v.form.state)"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <template v-slot:label>
                {{ labels.zip }}
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.zip.$model"
                :state="validateState(v.form.zip)"
                v-mask="defaultMask.zip"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <!--end:: Fieldset-->

      <!--begin:: Fieldset-->
      <template v-if="!hideAccountArea">
        <h3 class="text-dark text-center my-12">Account Details</h3>
        <b-row class="row">
          <b-col lg="6">
            <b-form-group>
              <template v-slot:label>
                {{ labels.routing_number }}
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.routing_number.$model"
                :state="validateState(v.form.routing_number)"
                v-mask="defaultMask.routingNumber"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <template v-slot:label>
                {{ labels.account_number }}
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="v.form.account_number.$model"
                :state="validateState(v.form.account_number)"
                v-mask="defaultMask.bankAccountNumber"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <!--end:: Fieldset-->
    </template>
    <template v-else>
      <!--begin:: Fieldset-->
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              {{ labels.first_name }}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
              v-model="v.form.first_name.$model"
              :state="validateState(v.form.first_name)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              {{ labels.last_name }}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
              v-model="v.form.last_name.$model"
              :state="validateState(v.form.last_name)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <template v-slot:label>
          {{ labels.email }}
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="text"
          class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          required
          v-model="v.form.email.$model"
          :state="validateState(v.form.email)"
        />
      </b-form-group>
      <!--end:: Fieldset-->
    </template>
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
    hideInfoArea: {
      type: Boolean,
      default: false,
    },
    hideContactArea: {
      type: Boolean,
      default: false,
    },
    hideAddressArea: {
      type: Boolean,
      default: false,
    },
    hideAccountArea: {
      type: Boolean,
      default: false,
    },
    miniMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.v.form.contact_type.$model;
    },
  },
};
</script>

<style lang="scss" scoped></style>
