<template>
  <KTWizardStepContent :step-number="4" title="Applicants">
    <template v-slot:save-btn>
      <!-- <b-btn
        v-cs-loading="saveAndExitSubmiting"
        variant="light-primary"
        size="lg"
        :disabled="footerSubmiting"
        @click="saveAndExit"
      >
        Save and Exit
      </b-btn> -->
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <template v-for="(kstep, kindex) in steps">
          <KTWizardStepContentNavItem
            :key="kindex"
            :order-number="kindex + 1"
            :title="kstep.title"
            :is-checked="step > kindex"
            :is-current="step == kindex"
          />
        </template>
      </KTWizardStepContentNavList>
    </template>
    <template v-slot:body>
      <template v-if="notReadyStripeAccounts.length > 0">
        <p>You can not invited applicants until following stripe accounts are successfully completed:</p>
        <ol class="p-1 m-0">
          <li
            v-for="(stripeAccount, index) in notReadyStripeAccounts"
            :key="index"
            class="d-flex align-items-center justify-content-between mb-1"
          >
            {{ stripeAccount.title }}
            <a v-if="isCoordinator && user.stripe_account_link" :href="user.stripe_account_link" target="_blank">
              Stripe link
            </a>
          </li>
        </ol>
      </template>
      <invite-form v-else-if="step == 0" :v="$v" :labels="labels" />
    </template>
    <template v-slot:footer>
      <b-btn
        :disabled="saveAndExitSubmiting"
        variant="light-primary"
        class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
        @click="back"
      >
        <span class="svg-icon svg-icon-md mr-2">
          <inline-svg src="/media/svg/icons/Navigation/Left-2.svg" />
        </span>
        Back
      </b-btn>

      <b-btn
        v-if="notReadyStripeAccounts.length == 0"
        v-cs-loading="footerSubmiting"
        variant="primary"
        class="font-weight-bolder font-size-h6 py-4 ml-auto"
        :disabled="saveAndExitSubmiting"
        :class="{ 'pl-8 pr-4': !isLastStep, 'px-8': isLastStep }"
        @click="next"
        id="next"
      >
        {{ isLastStep ? 'Finish' : 'Next' }}
        <span v-if="!isLastStep" class="svg-icon svg-icon-md ml-2">
          <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
        </span>
      </b-btn>
    </template>
  </KTWizardStepContent>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import InviteForm from './InviteForm';
import { startCase } from 'lodash';

import { mapActions } from 'vuex';
export default {
  name: 'CooperativeApplicantInviteStep',
  props: {
    cooperative: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    InviteForm,
  },
  validations: {
    form: {
      emails: { required },
    },
  },
  data() {
    return {
      saveAndExitSubmiting: false,
      footerSubmiting: false,
      step: 0,

      labels: {
        emails: 'Emails',
      },

      steps: [
        {
          title: 'Invite Applicant',
        },
      ],

      form: {
        emails: '',
      },
    };
  },
  computed: {
    isLastStep() {
      return this.step + 1 == this.steps.length;
    },
    notReadyStripeAccounts() {
      const conditions = [];

      for (const index in this.cooperative.ready_to_payment) {
        if (!this.cooperative.ready_to_payment[index]) {
          conditions.push({
            title: startCase(index),
            key: index,
          });
        }
      }

      return conditions;
    },
  },
  created() {},
  methods: {
    ...mapActions({
      inviteApplicants: 'applicants/invite',
      updateCooperative: 'cooperatives/update',
    }),
    async next() {
      if (!this.$v.form.$invalid) {
        $(".container").scrollTop(0);
        this.footerSubmiting;
        await this.checkAndSubmit();
        this.$emit('finish');
      } else {
        this.alertValidationFields(this.$v.form, this.labels);
        this.$v.form.$touch();
      }
    },
    back() {
      this.$emit('finish');
      // this.step -= 1;
    },

    async saveAndExit() {
      this.saveAndExitSubmiting = true;
      await this.checkAndSubmit(true);
      this.$emit('close');
    },
    async checkAndSubmit(isSaveClose = false) {
      try {
        await this.handleInviteApplicants(isSaveClose);
      } catch (error) {
        throw error;
      } finally {
        this.saveAndExitSubmiting = false;
        this.footerSubmiting = false;
      }
    },
    async handleInviteApplicants(isSaveClose = false) {
      const params = {
        cooperative_id: this.cooperative.id,
        emails: this.form.emails.split(','),
      };

      await this.inviteApplicants(this.jsonToFormData(params));

      await this.updateCooperative({
        ...this.cooperative,
        step_status: 4,
        finished: true,
      });

      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
