<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">Credit Criteria</h2>

    <!--begin:: Fieldset-->
    <b-form-group>
      <template v-slot:label>
        {{ labels.maximum_loan_ratio }}
        <span class="text-danger">*</span> 
      </template>
      <b-form-input
        type="number"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        required
        v-model="v.creditForm.maximum_loan_ratio.$model"
        :state="validateState(v.creditForm.maximum_loan_ratio)"
        :min="0"
        :max="100"
        :formatter="percentageFormatter"
      />
    </b-form-group>

    <b-form-group>
      <template v-slot:label>
        {{ labels.maximum_debt_ratio }}
        <span class="text-danger">*</span>
      </template>
      <b-form-input
        type="number"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        required
        v-model="v.creditForm.maximum_debt_ratio.$model"
        :state="validateState(v.creditForm.maximum_debt_ratio)"
        :min="0"
        :max="100"
        :formatter="percentageFormatter"
      />
    </b-form-group>

    <b-form-group>
      <template v-slot:label>
        {{ labels.minimum_credit_score }}
        <span class="text-danger">*</span>
      </template>
      <b-form-input
        type="text"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        required
        v-model="v.creditForm.minimum_credit_score.$model"
        :state="validateState(v.creditForm.minimum_credit_score)"
        v-mask="'###'"
      />
    </b-form-group>
    <!--end:: Fieldset-->
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
