<template>
  <div>
    <b-btn v-if="showSkip && currentStep === step" variant="outline-primary" size="sm" block class="font-weight-bolder" @click="$emit('clicked:skip', step)">
      Skip
    </b-btn>
    <b-btn v-if="currentStep === step" variant="primary" size="sm" block class="font-weight-bolder" @click="$emit('clicked:continue', step)">
      Continue 
    </b-btn>
    <b-btn v-if="currentStep > step" variant="secondary" size="sm" block class="font-weight-bolder" @click="$emit('clicked:edit', step)">
      Edit
    </b-btn>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
