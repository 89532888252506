<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">Bank Information</h2>
    <h5 class="text-muted text-center mb-12">Please provide us with your Bank's information</h5>

    <!--begin:: Fieldset-->
    <b-form-group>
      <template v-slot:label>
        {{ labels.bank_name }}
        <span class="text-danger">*</span>
      </template>
      <b-form-input
        type="text"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        required
        v-model="v.bankInformation.bank_name.$model"
        :state="validateState(v.bankInformation.bank_name)"
      />
    </b-form-group>
    <!--end:: Fieldset-->

    <!--begin:: Fieldset-->
    <h3 class="text-dark text-center my-12">Address</h3>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.bank_address }}
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.bankInformation.bank_address.$model"
            :state="validateState(v.bankInformation.bank_address)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.bank_suite }}
            <!-- <span class="text-danger">*</span> -->
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.bankInformation.bank_suite.$model"
            :state="validateState(v.bankInformation.bank_suite)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.bank_city }}
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.bankInformation.bank_city.$model"
            @keypress="onlyLetter($event)"
            :state="validateState(v.bankInformation.bank_city)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.bank_state }}
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="stateOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.bankInformation.bank_state.$model"
            :state="validateState(v.bankInformation.bank_state)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            {{ labels.bank_zip }}
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.bankInformation.bank_zip.$model"
            :state="validateState(v.bankInformation.bank_zip)"
            v-mask="defaultMask.zip"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!--end:: Fieldset-->

    <!--begin:: Fieldset-->
    <h3 class="text-dark text-center my-12">Account Details</h3>
    <b-row class="row">
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.routing_number }}
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.bankInformation.routing_number.$model"
            :state="validateState(v.bankInformation.routing_number)"
            v-mask="defaultMask.routingNumber"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.account_number }}
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.bankInformation.account_number.$model"
            :state="validateState(v.bankInformation.account_number)"
            v-mask="defaultMask.bankAccountNumber"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!--end:: Fieldset-->
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
