<template>
  <KTWizardStepContent :step-number="1" title="Details">
    <template v-slot:save-btn>
      <b-btn
        v-cs-loading="saveAndExitSubmiting"
        variant="light-primary"
        size="lg"
        :disabled="footerSubmiting"
        @click="saveAndExit"
      >
        Save and Exit
      </b-btn>
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <template v-for="(kstep, kindex) in steps">
          <KTWizardStepContentNavItem
            :key="kindex"
            :order-number="kindex + 1"
            :title="kstep.title"
            :is-checked="step > kindex"
            :is-current="step == kindex"
          />
        </template>
      </KTWizardStepContentNavList>
    </template>
    <template v-slot:body>
      <cooperative-details v-show="step == 0" :v="$v" :labels="labels" @logo-removed="logoRemoved" />
      <bank-details v-show="step == 1" :v="$v" :labels="labels" />
    </template>
    <template v-slot:footer>
      <b-btn
        v-if="step > 0"
        :disabled="saveAndExitSubmiting"
        variant="light-primary"
        class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
        @click="back"
      >
        <span class="svg-icon svg-icon-md mr-2">
          <inline-svg src="/media/svg/icons/Navigation/Left-2.svg" />
        </span>
        Back
      </b-btn>

      <b-btn
        v-cs-loading="footerSubmiting"
        variant="primary"
        class="font-weight-bolder font-size-h6 py-4 ml-auto"
        :disabled="saveAndExitSubmiting"
        id="next"
        :class="{ 'pl-8 pr-4': !isLastStep, 'px-8': isLastStep }"
        @click="next"
      >
        {{ isLastStep ? 'Finish' : 'Next' }}
        <span v-if="!isLastStep" class="svg-icon svg-icon-md ml-2">
          <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
        </span>
      </b-btn>
    </template>
  </KTWizardStepContent>
</template>

<script>
import { required, integer, maxLength, minLength } from 'vuelidate/lib/validators';
import CooperativeDetails from './CooperativeDetails';
import BankDetails from './BankDetails';
import { mapActions } from 'vuex';
export default {
  name: 'CooperativeDetailsStep',
  props: {
    cooperative: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CooperativeDetails,
    BankDetails,
  },
  validations: {
    cooperativeForm: {
      logo: {},
      company_name: { required },
      street_address: { required },
      suite: {},
      city: { required },
      state: { required },
      zip: { required, integer, minLength: minLength(5), maxLength: maxLength(5) },
    },
    bankInformation: {
      bank_name: { required },
      bank_address: { required },
      bank_suite: {},
      bank_city: { required },
      bank_state: { required },
      bank_zip: { required, integer, minLength: minLength(5), maxLength: maxLength(5) },
      routing_number: { required, integer, minLength: minLength(9), maxLength: maxLength(9) },
      account_number: { required, integer, minLength: minLength(8), maxLength: maxLength(12) },
    },
  },
  data() {
    return {
      saveAndExitSubmiting: false,
      footerSubmiting: false,
      step: 0,
      labels: {
        logo: "Company's Logo",
        company_name: "Company's name",
        street_address: 'Street address',
        suite: 'Apt/Suite',
        city: 'City',
        state: 'State',
        zip: 'Zip',

        bank_name: "Bank's name",
        bank_address: "Bank's address",
        bank_suite: 'Apt/Suite',
        bank_city: 'City',
        bank_state: 'State',
        bank_zip: 'Zip',
        routing_number: 'Routing number (9 digits)',
        account_number: 'Account number (8-12 digits)',
      },
      steps: [
        {
          title: 'Cooperative Information',
        },
        {
          title: 'Bank Information',
        },
      ],
      cooperativeForm: {},
      bankInformation: {},
    };
  },
  computed: {
    isLastStep() {
      return this.step + 1 == this.steps.length;
    },
  },
  created() {
    this.initForms(this.cooperative);
  },
  watch:{
    step:{
      handler(){
        $(".wizard-custom-content-body").scrollTop(0);
      }
    }
  },
  methods: {
    ...mapActions({
      getCooperative: 'cooperatives/show',
      createCooperative: 'cooperatives/create',
      updateCooperative: 'cooperatives/update',
      removeCooperativeLogo: 'cooperatives/removeCooperativeLogo',
    }),
    async next() {
      if (this.step == 0) {
        if (!this.$v.cooperativeForm.$invalid) {
          return (this.step += 1);
        } else {
          this.alertValidationFields(this.$v.cooperativeForm, this.labels);
          this.$v.cooperativeForm.$touch();
        }
      }

      if (this.isLastStep) {
        if (!this.$v.bankInformation.$invalid) {
          this.footerSubmiting = true;
          await this.checkAndSubmit();
          this.$emit('finish');
        } else {
          this.alertValidationFields(this.$v.bankInformation, this.labels);
          this.$v.bankInformation.$touch();
        }
      }
    },
    back() {
      this.step -= 1;
    },
    initForms(form) {
      this.cooperativeForm = {
        logo: form.logo || '',
        company_name: form.company_name || '',
        street_address: form.street_address || '',
        suite: form.suite || '',
        city: form.city || '',
        state: this.getStateVal(form.state),
        zip: form.zip || '',
      };

      this.bankInformation = {
        bank_name: form.bank_name || '',
        bank_address: form.bank_address || '',
        bank_suite: form.bank_suite || '',
        bank_city: form.bank_city || '',
        bank_state: this.getStateVal(form.bank_state),
        bank_zip: form.bank_zip || '',
        routing_number: form.routing_number || '',
        account_number: form.account_number || '',
      };
    },
    logoRemoved(file) {
      const id = this.cooperative.id;
      if (id) {
        this.removeCooperativeLogo({ id }).then((result) => {
          this.cooperativeForm.logo = '';
        });
      }
    },
    async saveAndExit() {
      this.saveAndExitSubmiting = true;
      await this.checkAndSubmit(true);
      this.$emit('close');
    },
    async checkAndSubmit(isSaveClose = false) {
      try {
        await this.updateOrCreateCooperative(isSaveClose);
      } catch (error) {
        throw error;
      } finally {
        this.saveAndExitSubmiting = false;
        this.footerSubmiting = false;
      }
    },
    async updateOrCreateCooperative(isSaveClose = false) {
      let id = this.cooperative.id;
      const action = id ? this.updateCooperative : this.createCooperative;

      let params = {
        coordinator_id: this.user.id,
        step_status: this.step,
        ...this.cooperative,
        ...this.cooperativeForm,
        ...this.bankInformation,
      };

      if (!isSaveClose && params.step_status < 1) params.step_status = 1;

      params.status = params.step_status < 3 || !id ? 'Not Active' : 'Active';

      if (!params.logo || typeof params.logo == 'string') delete params.logo;

      const form = { cooperative: params };

      if (id) form.id = id;

      await action(this.jsonToFormData(form)).then((data) => {
        if (!id) id = data.id;
      });

      if (!this.cooperative.id) {
        this.$router.push({ name: 'cooperatives-create', params: { cooperativeId: id } });
      } else {
        await this.getCooperative(id);
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
