<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">Instructions</h2>

    <!--begin:: Fieldset-->
    <b-form-group>
      <template v-slot:label>
        {{ labels.who_schedules_interview }}
      </template>
      <div class="radio-inline">
        <label class="radio">
          <input
            type="radio"
            name="app-coord"
            v-model="v.instructionsForm.who_schedules_interview.$model"
            value="App Coordinator"
          />
          <span />
          App Coordinator
        </label>
        <label class="radio">
          <input
            type="radio"
            name="board-president"
            v-model="v.instructionsForm.who_schedules_interview.$model"
            value="Board President"
          />
          <span />
          Board President
        </label>
      </div>
    </b-form-group>
    <!--end:: Fieldset-->

    <!--begin:: Fieldset-->
    <h3 class="text-dark text-center my-12">Additional Instructions</h3>
    <b-form-group>
      <template v-slot:label>
        {{ labels.additional_instruction }}
      </template>
      <b-form-textarea
        v-model="v.instructionsForm.additional_instruction.$model"
        placeholder="Enter something..."
        rows="3"
        max-rows="6"
      />
    </b-form-group>
    <b-form-group>
      <template v-slot:label>
        {{ labels.remarks }}
      </template>
      <b-form-textarea
        v-model="v.instructionsForm.remarks.$model"
        placeholder="Enter something..."
        rows="3"
        max-rows="6"
      />
    </b-form-group>
    <!--end:: Fieldset-->
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
