<template>
  <KTWizardStepContent :step-number="3" title="Application Settings">
    <template v-slot:save-btn>
      <b-btn
        v-cs-loading="saveAndExitSubmiting"
        variant="light-primary"
        size="lg"
        :disabled="footerSubmiting"
        @click="saveAndExit"
      >
        Save and Exit
      </b-btn>
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <template v-for="(kstep, kindex) in steps">
          <KTWizardStepContentNavItem
            :key="kindex"
            :order-number="kindex + 1"
            :title="kstep.title"
            :is-checked="step > kindex"
            :is-current="step == kindex"
          />
        </template>
      </KTWizardStepContentNavList>
    </template>
    <template v-slot:body>
      <credit-creteria v-if="step == 0" :v="$v" :labels="labels" />
      <fees v-if="step == 1" :v="$v" :labels="labels" />
      <supplemental-form v-if="step == 2" :v="$v" :labels="labels" :cooperative="cooperative" />
      <instructions-form v-if="step == 3" :v="$v" :labels="labels" />
    </template>
    <template v-slot:footer>
      <b-btn
        v-if="step > 0"
        :disabled="saveAndExitSubmiting"
        variant="light-primary"
        class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
        @click="back"
      >
        <span class="svg-icon svg-icon-md mr-2">
          <inline-svg src="/media/svg/icons/Navigation/Left-2.svg" />
        </span>
        Back
      </b-btn>

      <b-btn
        v-cs-loading="footerSubmiting"
        variant="primary"
        class="font-weight-bolder font-size-h6 py-4 ml-auto"
        :disabled="saveAndExitSubmiting"
        :class="{ 'pl-8 pr-4': !isLastStep, 'px-8': isLastStep }"
        @click="next"
        id="next"
      >
        {{ isLastStep ? 'Finish' : 'Next' }}
        <span v-if="!isLastStep" class="svg-icon svg-icon-md ml-2">
          <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
        </span>
      </b-btn>
    </template>
  </KTWizardStepContent>
</template>

<script>
import { required, integer, maxLength, minLength } from 'vuelidate/lib/validators';
import CreditCreteria from './CreditCreteria';
import Fees from './Fees';
import SupplementalForm from './SupplementalForm';
import InstructionsForm from './InstructionsForm';
import { mapActions } from 'vuex';
export default {
  name: 'CooperativeSettingsStep',
  props: {
    cooperative: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CreditCreteria,
    Fees,
    SupplementalForm,
    InstructionsForm,
  },
  validations: {
    creditForm: {
      maximum_loan_ratio: { required },
      maximum_debt_ratio: { required },
      minimum_credit_score: { required, integer, minLength: minLength(1), maxLength: maxLength(3) },
    },
    feesForm: {
      // refundable_move_in_fee: { required },
      // refundable_move_out_fee: { required },
      // refundable_carpeting_fee: { required },
      // unrefundable_move_in_fee: { required },
      // unrefundable_move_out_fee: { required },
      // select_flip_tax: { required },
      // flip_tax: { required },
      // pet_fee: { required },
      // managing_agent_fee: { required },
      // misc_cooperative_fee: { required },
      application_fee: { required },
      is_each_applicants_pays_fee: {},
    },
    supplementalForm: {
      use_supplemental_forms: {},
      background_check: {},
    },
    instructionsForm: {
      who_schedules_interview: { required },
      additional_instruction: {},
      remarks: {},
    },
  },
  data() {
    return {
      saveAndExitSubmiting: false,
      footerSubmiting: false,
      step: 0,

      labels: {
        maximum_loan_ratio: 'Maximum loan to value ratio',
        maximum_debt_ratio: 'Maximum debt to income ratio',
        minimum_credit_score: 'Minimum Credit Score',

        // refundable_move_in_fee: 'Move in fee',
        // refundable_move_out_fee: 'Move out fee',
        // refundable_carpeting_fee: 'Carpeting fee',
        // unrefundable_move_in_fee: 'Move in fee',
        // unrefundable_move_out_fee: 'Move out fee',
        // select_flip_tax: 'Select Flip Tax',
        // flip_tax: 'Flip tax',
        // pet_fee: 'Pet fee',
        // managing_agent_fee: 'Managing agent fee',
        // misc_cooperative_fee: 'Misc cooperative fee',
        application_fee: 'Application fee',
        is_each_applicants_pays_fee: 'Each applicant pays the application fee',
        use_supplemental_forms: 'Supplemental Application Form',
        background_check: 'Criminal Check, Sex Offender Registry Check, Credit Check ($125)',

        who_schedules_interview: 'Who schedules the interview',
        additional_instruction: 'Additional Instructions',
        remarks: 'Remarks',
      },

      steps: [
        {
          title: 'Credit Criteria',
          form: 'creditForm',
        },
        {
          title: 'Fees',
          form: 'feesForm',
        },
        {
          title: 'Forms',
          form: 'supplementalForm',
        },
        {
          title: 'Instructions',
          form: 'instructionsForm',
        },
      ],

      creditForm: {},
      feesForm: {},
      supplementalForm: {},
      instructionsForm: {},
    };
  },
  computed: {
    isLastStep() {
      return this.step + 1 == this.steps.length;
    },
  },
  created() {
    this.initForms(this.cooperative);
  },
  watch: {
    step: {
      handler() {
        $('.wizard-custom-content-body').scrollTop(0);
      },
    },
  },
  methods: {
    ...mapActions({
      getCooperative: 'cooperatives/show',
      updateCooperative: 'cooperatives/update',
    }),
    async next() {
      const step = this.steps[this.step];

      if (!this.$v[step.form].$invalid) {
        if (this.isLastStep) {
          this.footerSubmiting = true;
          await this.checkAndSubmit();
          this.$emit('finish');
        } else {
          this.step += 1;
        }
      } else {
        this.alertValidationFields(this.$v[step.form], this.labels);
        this.$v[step.form].$touch();
      }
    },
    back() {
      this.step -= 1;
    },
    initForms(form) {
      this.creditForm = {
        maximum_loan_ratio: form.maximum_loan_ratio,
        maximum_debt_ratio: form.maximum_debt_ratio,
        minimum_credit_score: form.minimum_credit_score,
      };

      this.feesForm = {
        // refundable_move_in_fee: this.centToDollar(form.refundable_move_in_fee),
        // refundable_move_out_fee: this.centToDollar(form.refundable_move_out_fee),
        // refundable_carpeting_fee: this.centToDollar(form.refundable_carpeting_fee),
        // unrefundable_move_in_fee: this.centToDollar(form.unrefundable_move_in_fee),
        // unrefundable_move_out_fee: this.centToDollar(form.unrefundable_move_out_fee),
        // select_flip_tax: this.getSelectFlipTaxVal(form.select_flip_tax),
        // flip_tax: this.getFlipTaxVal(form.select_flip_tax, form.flip_tax, true),
        // pet_fee: this.centToDollar(form.pet_fee),
        // managing_agent_fee: this.centToDollar(form.managing_agent_fee),
        // misc_cooperative_fee: this.centToDollar(form.misc_cooperative_fee),
        application_fee: this.centToDollar(form.application_fee),
        is_each_applicants_pays_fee: form.is_each_applicants_pays_fee,
      };

      this.supplementalForm = {
        use_supplemental_forms: form.use_supplemental_forms,
        supplementalForm: form.supplementalForm,
        background_check: form.background_check ?? true,
      };
      this.instructionsForm = {
        who_schedules_interview: form.who_schedules_interview,
        additional_instruction: form.additional_instruction,
        remarks: form.remarks,
      };
    },

    handleFeesForm() {
      const feesForm = this.$_.cloneDeep(this.feesForm);
      // feesForm.refundable_move_in_fee = this.dollarToCent(feesForm.refundable_move_in_fee);
      // feesForm.refundable_move_out_fee = this.dollarToCent(feesForm.refundable_move_out_fee);
      // feesForm.refundable_carpeting_fee = this.dollarToCent(feesForm.refundable_carpeting_fee);
      // feesForm.unrefundable_move_in_fee = this.dollarToCent(feesForm.unrefundable_move_in_fee);
      // feesForm.unrefundable_move_out_fee = this.dollarToCent(feesForm.unrefundable_move_out_fee);
      // feesForm.flip_tax = this.getFlipTaxVal(feesForm.select_flip_tax, feesForm.flip_tax);
      // feesForm.pet_fee = this.dollarToCent(feesForm.pet_fee);
      // feesForm.managing_agent_fee = this.dollarToCent(feesForm.managing_agent_fee);
      // feesForm.misc_cooperative_fee = this.dollarToCent(feesForm.misc_cooperative_fee);
      feesForm.application_fee = this.dollarToCent(feesForm.application_fee);

      return feesForm;
    },

    async saveAndExit() {
      this.saveAndExitSubmiting = true;
      await this.checkAndSubmit(true);
      this.$emit('close');
    },
    async checkAndSubmit(isSaveClose = false) {
      try {
        await this.handleUpdateCooperative(isSaveClose);
      } catch (error) {
        throw error;
      } finally {
        this.saveAndExitSubmiting = false;
        this.footerSubmiting = false;
      }
    },
    async handleUpdateCooperative(isSaveClose = false) {
      const params = {
        coordinator_id: this.user.id,
        ...this.cooperative,
        ...this.creditForm,
        ...this.handleFeesForm(),
        ...this.supplementalForm,
        ...this.instructionsForm,
      };

      if (!isSaveClose && params.step_status < 3) params.step_status = 3;

      params.status = params.step_status < 3 ? 'Not Active' : 'Active';

      await this.updateCooperative(params);

      await this.getCooperative(this.cooperative.id);

      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
