<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">Invite Applicant</h2>
    <h5 class="text-muted text-center mb-12">Invite applicants to apply to cooperative</h5>

    <!--begin:: Fieldset-->
    <b-form-group>
      <template v-slot:label>
        {{ labels.emails }}

        <span class="text-danger">*</span>
      </template>
      <b-form-input
        type="text"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        required
        v-model="v.form.emails.$model"
        :state="validateState(v.form.emails)"
      />
      <small class="text-muted">
        You can send invite multiple applicants, just separate addresses with a comma.
      </small>
    </b-form-group>
    <!--end:: Fieldset-->
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
