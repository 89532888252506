<template>
  <KTWizardStepContent :step-number="2" title="People">
    <template v-slot:save-btn>
      <b-btn
        v-cs-loading="saveAndExitSubmiting"
        variant="light-primary"
        size="lg"
        :disabled="footerSubmiting"
        @click="saveAndExit"
      >
        Save and Exit
      </b-btn>
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <template v-for="(kstep, kindex) in steps">
          <KTWizardStepContentNavItem
            :key="kindex"
            :order-number="kindex + 1"
            :title="kstep.title"
            :is-checked="step > kindex"
            :is-current="step == kindex"
          />
        </template>
      </KTWizardStepContentNavList>
    </template>
    <template v-slot:body>
      <template v-for="(kstep, kindex) in steps">
        <contact-form v-if="!loading && step === kindex" v-bind="kstep.attrs" :key="kindex" :v="$v" :labels="labels" />
      </template>
    </template>
    <template v-slot:footer>
      <template v-if="loading">
        <spinner bg-transparent />
      </template>
      <template v-else>
        <b-btn
          v-if="step > 0"
          :disabled="saveAndExitSubmiting"
          variant="light-primary"
          class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
          @click="back"
        >
          <span class="svg-icon svg-icon-md mr-2">
            <inline-svg src="/media/svg/icons/Navigation/Left-2.svg" />
          </span>
          Back
        </b-btn>

        <b-btn
          v-cs-loading="footerSubmiting"
          variant="primary"
          class="font-weight-bolder font-size-h6 py-4 ml-auto"
          :disabled="saveAndExitSubmiting"
          :class="{ 'pl-8 pr-4': !isLastStep, 'px-8': isLastStep }"
          @click="next"
          id="next"
        >
          {{ isLastStep ? 'Finish' : 'Next' }}
          <span v-if="!isLastStep" class="svg-icon svg-icon-md ml-2">
            <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
          </span>
        </b-btn>
      </template>
    </template>
  </KTWizardStepContent>
</template>

<script>
import { required, integer, email, maxLength, minLength } from 'vuelidate/lib/validators';
import ContactForm from './ContactForm';
import { mapActions } from 'vuex';
export default {
  name: 'CooperativePeopleDetailsStep',
  props: {
    cooperative: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ContactForm,
  },
  validations: {
    form: {
      contact_type: {},
      company_name: {},
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: {minLength: minLength(13)},
      street_address: {},
      suite: {},
      city: {},
      state: {},
      zip: { integer, minLength: minLength(5), maxLength: maxLength(5) },
      routing_number: { integer, minLength: minLength(9), maxLength: maxLength(9) },
      account_number: { integer, minLength: minLength(8), maxLength: maxLength(12) },
    },
  },
  data() {
    return {
      saveAndExitSubmiting: false,
      footerSubmiting: false,
      loading: false,
      step: 0,
      labels: {
        company_name: "Company's name",
        first_name: 'First name',
        last_name: 'Last name',
        email: 'Email address',
        phone: 'Phone number',
        street_address: 'Street address',
        suite: 'Apt/Suite',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        routing_number: 'Routing number (9 digits)',
        account_number: 'Account number (8-12 digits)',
      },
      steps: [
        {
          title: 'Managing Agent',
        },
        {
          title: 'Accounts Receivable',
          attrs: { miniMode: true },
        },
        {
          title: 'Transfer Agent',
          attrs: { hideAccountArea: true },
        },
        {
          title: 'Insurance Broker',
          attrs: { hideAccountArea: true },
        },
      ],
      form: {},
      contacts: {},
    };
  },
  computed: {
    isLastStep() {
      return this.step + 1 == this.steps.length;
    },
  },
  async created() {
    this.loading = true;
    await this.$await(this.handleContacts());
    await this.$await(this.initForms(this.steps[0].title));
    this.loading = false;
  },
  watch:{
    step:{
      handler(){
        $(".wizard-custom-content-body").scrollTop(0);
      }
    }
  },
  methods: {
    ...mapActions({
      getContacts: 'contacts/index',
      createContact: 'contacts/create',
      updateContact: 'contacts/update',
      updateCooperative: 'cooperatives/update',
    }),
    async handleContacts() {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        page: 1,
        where: {
          cooperative_id: this.cooperative.id,
        },
      };
      await this.getContacts(params).then((data) => {
        for (const contact of data || []) {
          const stepType = this.steps.find((s) => s.title == contact.attributes.contact_type);
          if (stepType) {
            this.contacts[stepType.title] = {
              id: contact.id,
              ...contact.attributes,
            };
          }
        }
      });
    },
    async next() {
      if (!this.$v.form.$invalid) {
        this.footerSubmiting = true;
        await this.checkAndSubmit();
        if (this.isLastStep) {
          this.$emit('finish');
        } else {
          this.step += 1;
          const step = this.steps[this.step];
          this.initForms(step.title);
        }
      } else {
        this.alertValidationFields(this.$v.form, this.labels);
        this.$v.form.$touch();
      }
      $("body").scrollTop();
    },
    back() {
      this.step -= 1;
      const step = this.steps[this.step];
      this.initForms(step.title);
    },
    async initForms(type) {
      const form = this.contacts[type] || {};
      this.form = {
        id: form.id || null,
        company_name: form.company_name || '',
        first_name: form.first_name || '',
        last_name: form.last_name || '',
        email: form.email || '',
        phone: form.phone || '',
        street_address: form.street_address || '',
        suite: form.suite || '',
        city: form.city || '',
        state: await this.getStateVal(form.state),
        zip: form.zip || '',
        routing_number: form.routing_number || '',
        account_number: form.account_number || '',
        status: form.status || 'Active',
        contact_type: form.contact_type || type,
      };
      await this.$v.form.$reset();
      const div = document.querySelector('.wizard-custom-content-body');
      if (div) div.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async saveAndExit() {
      this.saveAndExitSubmiting = true;
      await this.checkAndSubmit(true);
      this.$emit('close');
    },
    async checkAndSubmit(isSaveClose = false) {
      try {
        await this.updateOrCreateContact(isSaveClose);
      } catch (error) {
        throw error;
      } finally {
        this.saveAndExitSubmiting = false;
        this.footerSubmiting = false;
      }
    },
    async updateOrCreateContact(isSaveClose = false) {
      const form = this.form;
      let id = form.id;
      const action = id ? this.updateContact : this.createContact;

      const params = {
        cooperative_id: this.cooperative.id,
        ...this.form,
      };

      await action(params).then((data) => {
        if (!id) form.id = data.id;
        this.contacts[form.contact_type] = form;
      });

      if (this.isLastStep && this.cooperative.step_status < 2) {
        const params = {
          ...this.cooperative,
          coordinator_id: this.user.id,
          step_status: 2,
        };
        await this.updateCooperative(params);
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
