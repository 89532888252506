<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">Cooperative Details</h2>
    <h5 class="text-muted text-center mb-12">Please provide us with your Cooperative's information</h5>

    <!--begin::Form Group-->
    <b-form-group>
      <cs-dropzone
        ref="company_logo"
        v-model="v.cooperativeForm.logo.$model"
        :msg-title="labels.logo"
        msg-dec="Only images are allowed for upload"
        @removed="(v) => $emit('logo-removed', v)"
      >
      </cs-dropzone>
    </b-form-group>

    <b-form-group>
      <template v-slot:label>
        {{ labels.company_name }}
        <span class="text-danger">*</span>
      </template>
      <b-form-input
        type="text"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        v-model="v.cooperativeForm.company_name.$model"
        :state="validateState(v.cooperativeForm.company_name)"
        required
      />
    </b-form-group>
    <!--end::Form Group-->
    <h3 class="text-dark text-center my-12">Address</h3>
    <!--begin:: Fieldset-->
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.street_address }}
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.cooperativeForm.street_address.$model"
            :state="validateState(v.cooperativeForm.street_address)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.suite }}
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="v.cooperativeForm.suite.$model"
            :state="validateState(v.cooperativeForm.suite)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.city }}
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.cooperativeForm.city.$model"
            @keypress="onlyLetter($event)"
            :state="validateState(v.cooperativeForm.city)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            {{ labels.state }}
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="stateOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.cooperativeForm.state.$model"
            :state="validateState(v.cooperativeForm.state)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            {{ labels.zip }}
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-mask="defaultMask.zip"
            v-model="v.cooperativeForm.zip.$model"
            :state="validateState(v.cooperativeForm.zip)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!--end:: Fieldset-->
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
