<template>
  <b-card class="card-custom" body-class="p-0">
    <!--begin::Wizard 5-->
    <div v-if="!loading" class="wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid p-xxl-12">
      <!--begin::Aside-->
      <b-form class="wizard-custom wizard-aside d-flex flex-column flex-row-auto w-100 w-lg-400px mr-lg-12">
        <!--begin::Aside Top-->
        <div class="d-flex flex-column-fluid flex-column">
          <!--begin: Wizard Nav-->
          <div class="wizard-nav d-flex d-flex justify-content-center">
            <!--begin::Wizard Steps-->
            <KTWizardStepsList>
              <template v-for="(kstep, kindex) in steps">
                <KTWizardStepsItem
                  :order-number="kindex + 1"
                  :key="kindex"
                  :title="kstep.title"
                  :description="kstep.description"
                  :is-checked="coopStepStatus > kindex"
                  :disabled="kstep.disabled"
                  :is-current="coopStepStatus === kindex"
                >
                  <template v-slot:btn>
                    <step-btns
                      :show-skip="kstep.showSkip"
                      :current-step="coopStepStatus"
                      :step="kindex"
                      @clicked:continue="openStep"
                      @clicked:edit="openStep"
                      @clicked:skip="skipStep"
                    ></step-btns>
                  </template>
                </KTWizardStepsItem>
              </template>
            </KTWizardStepsList>
            <!--end::Wizard Steps-->
          </div>
          <!--end: Wizard Nav-->
        </div>
        <!--end::Aside Top-->
      </b-form>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="wizard-content d-flex flex-column flex-row-fluid p-8 p-lg-0">
        <!--begin::Form-->
        <div
          class="wizard-content-inner d-flex justify-content-center flex-row-fluid h-lg-600px pt-8 pb-30 py-lg-40 px-8 px-lg-16"
          :style="{ backgroundImage: `url(${content.bg})` }"
        >
          <div class="text-center">
            <div v-if="coopStepStatus < steps.length">
              <h1 class="mb-8">{{ steps[coopStepStatus].title }}</h1>
              <h4>{{ steps[coopStepStatus].contentDesc }}</h4>
            </div>

            <div v-else>
              <div class="mb-8">
                <i class="text-success fas fa-check-circle" :style="{ fontSize: '50px' }" />
              </div>
              <h1 class="mb-8">The form was completed!</h1>
              <h4>Thank you!</h4>
              <router-link :to="{ name: 'cooperatives-index' }">
                <b-btn variant="primary" size="sm" block class="font-weight-bolder">
                  Finish
                </b-btn>
              </router-link>
            </div>
          </div>
        </div>
        <!--end::Form-->
      </div>
      <!--end::Content-->

      <!--begin::Step 1-->
      <cooperatives-create-details
        v-if="insideTheStep && step == 0"
        @close="saveClose"
        @finish="saveClose"
        :cooperative="cooperative"
      />
      <!--end::Step 1-->

      <!--begin::Step 2-->
      <cooperatives-create-people
        v-if="insideTheStep && step == 1"
        @close="saveClose"
        @finish="saveClose"
        :cooperative="cooperative"
      />
      <!--end::Step 2-->

      <!--begin::Step 3-->
      <cooperatives-create-settings
        v-if="insideTheStep && step == 2"
        @close="saveClose"
        @finish="saveClose"
        :cooperative="cooperative"
      />
      <!--end::Step 3-->

      <!--begin::Step 4-->
      <invite-form
        v-if="insideTheStep && step == 3"
        @close="saveClose"
        @finish="saveClose"
        :cooperative="cooperative"
      />

      <!--end::Step 4-->
    </div>
    <div v-else class="position-relative p-40">
      <spinner bg-transparent />
    </div>
    <!--end::Wizard 5-->
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CooperativesCreateDetails from './../../../components/cooperative/wizard/details/Main';
import CooperativesCreatePeople from './../../../components/cooperative/wizard/people/Main';
import CooperativesCreateSettings from './../../../components/cooperative/wizard/settings/Main';
import InviteForm from './../../../components/cooperative/wizard/invites/Main';
import StepBtns from './../../../components/cooperative/wizard/StepBtns';
export default {
  name: 'cooperatives-create',
  components: {
    StepBtns,
    CooperativesCreateDetails,
    CooperativesCreatePeople,
    CooperativesCreateSettings,
    InviteForm,
  },
  data() {
    return {
      content: { bg: '/media/svg/illustrations/visuals.svg' },
      loading: false,
      step: 0,
      insideTheStep: false,
      steps: [
        {
          title: 'Details',
          description: 'Cooperative Information, Bank Information',
          contentDesc:
            'Please be careful with info. It is so so important to make your our life so much easy and bring a joy to our town!',
        },
        {
          title: 'People',
          description: 'Managing Agent, Accounts Receivable, Transfer Agent, Insurance Broker',
          contentDesc:
            'Please be careful with info. It is so so important to make your our life so much easy and bring a joy to our town!',
        },
        {
          title: 'Application Settings',
          description: 'Credit Criteria, Fees, Forms, Instructions',
          contentDesc:
            'Please be careful with info. It is so so important to make your our life so much easy and bring a joy to our town!',
        },
        {
          title: 'Applicants',
          description: 'Invite Applicant Page',
          contentDesc:
            'Please be careful with info. It is so so important to make your our life so much easy and bring a joy to our town!',
          showSkip: true,
          disabled: !this.coopReadyToPayment
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      cooperative: 'cooperatives/item',
    }),
    coopStepStatus() {
      return this.cooperative.step_status || 0;
    },
    coopReadyToPayment() {
      if (!this.cooperative) return false

      for (const index in this.cooperative.ready_to_payment) {
        if (!this.cooperative.ready_to_payment[index]) {
          return false
        }
      }

      return true
    }
  },
  methods: {
    ...mapActions({
      getCooperative: 'cooperatives/show',
      updateCooperative: 'cooperatives/update',
      inviteApplicants: 'applicants/invite',
    }),
    defaultValues(){
      this.step = 0
      this.insideTheStep = false
    },
    skipStep(step) {
      if (step == 3) {
        this.updateCooperative({
          ...this.cooperative,
          step_status: 4,
          finished: true
        });
      }
    },
    openStep(step) {
      this.step = step;
      this.insideTheStep = true;
    },
    saveClose() {
      this.insideTheStep = false;
    },

    getCooperativeData(type) {
      const id = this.$route.params.cooperativeId;
      if (!id) return;
      this.loading = true;
      this.getCooperative(id).finally(() => (this.loading = false));
    },
  },
  created(){
    const id = this.$route.params.cooperativeId;
    if (!id) {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
    }
  },
  mounted() {
    this.getCooperativeData();
  },

  watch: {
    '$route.params.cooperativeId'(val) {
      this.getCooperativeData();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/pages/wizard/wizard-5';
</style>
