<template>
  <div>
    <h2 class="h1 text-dark text-center mb-3">Fees</h2>

    <!--begin::Fieldset-->
    <!--     <h3 class="text-dark text-center my-12">Refundable</h3>
    <b-row>
      <b-col lg="4">
        <b-form-group :state="validateState(v.feesForm.refundable_move_in_fee)">
          <template v-slot:label>
            {{ labels.refundable_move_in_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.refundable_move_in_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :state="validateState(v.feesForm.refundable_move_out_fee)">
          <template v-slot:label>
            {{ labels.refundable_move_out_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.refundable_move_out_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :state="validateState(v.feesForm.refundable_carpeting_fee)">
          <template v-slot:label>
            {{ labels.refundable_carpeting_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.refundable_carpeting_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <!--end:: Fieldset-->

    <!--begin::Fieldset-->
    <!--   <h3 class="text-dark text-center my-12">Non Refundable</h3>
    <b-row>
      <b-col lg="6">
        <b-form-group :state="validateState(v.feesForm.unrefundable_move_in_fee)">
          <template v-slot:label>
            {{ labels.unrefundable_move_in_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.unrefundable_move_in_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group :state="validateState(v.feesForm.unrefundable_move_out_fee)">
          <template v-slot:label>
            {{ labels.unrefundable_move_out_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.unrefundable_move_out_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <!--end:: Fieldset-->

    <!--begin::Fieldset-->
    <!-- <h3 class="text-dark text-center my-12">Flip Tax</h3>
    <b-row>
      <b-col lg="4">
        <b-form-group>
          <template v-slot:label>
            {{ labels.select_flip_tax }}
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="v.feesForm.select_flip_tax.$model"
            :options="flipTaxes"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            :state="validateState(v.feesForm.select_flip_tax)"
            @input="v.feesForm.flip_tax.$model = 0"
          />
        </b-form-group>
      </b-col>
      <b-col lg="8">
        <b-form-group :state="validateState(v.feesForm.flip_tax)">
          <template v-slot:label>
            {{ labels.flip_tax }}
            <span class="text-danger">*</span>
          </template>

          <b-input-group
            v-if="v.feesForm.select_flip_tax.$model == 'Percent per share' || v.feesForm.select_flip_tax.$model == ''"
            class="input-group-alt input-group-alt-has-append input-group-alt-has-prepend"
          >
            <b-form-input
              type="number"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
              v-model="v.feesForm.flip_tax.$model"
              :min="0"
              :max="100"
              :formatter="percentageFormatter"
            />
            <template v-slot:prepend>
              %
            </template>
          </b-input-group>

          <v-money
            v-else
            v-model="v.feesForm.flip_tax.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <!--end:: Fieldset-->

    <!--begin::Fieldset-->
    <!--  <h3 class="text-dark text-center my-12">Other Fees</h3>
    <b-row>
      <b-col lg="4">
        <b-form-group :state="validateState(v.feesForm.pet_fee)">
          <template v-slot:label>
            {{ labels.pet_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.pet_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :state="validateState(v.feesForm.managing_agent_fee)">
          <template v-slot:label>
            {{ labels.managing_agent_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.managing_agent_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :state="validateState(v.feesForm.misc_cooperative_fee)">
          <template v-slot:label>
            {{ labels.misc_cooperative_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.misc_cooperative_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
    <!--end:: Fieldset-->

    <!--begin::Fieldset-->
    <h3 class="text-dark text-center my-12">Application Fee</h3>
    <b-row>
      <b-col lg="12">
        <b-form-group :state="validateState(v.feesForm.application_fee)">
          <template v-slot:label>
            {{ labels.application_fee }}
            <span class="text-danger">*</span>
          </template>
          <v-money
            v-model="v.feesForm.application_fee.$model"
            v-bind="moneyMask"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group class="mrt30">
          <label class="checkbox checkbox-lg">
            <input id="each_pay" type="checkbox" v-model="v.feesForm.is_each_applicants_pays_fee.$model" />
            <span class="mr-2"></span> Each applicant pays the application fee
          </label>
        </b-form-group>
        <div class="mt-6 alert alert-light" role="alert">
          If not checked only the first applicant pays the application fee and the rest pays only background check fee
        </div>
      </b-col>
    </b-row>
    <!--end:: Fieldset-->
  </div>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.mrt30 {
  margin-top: 30px;
}
</style>
